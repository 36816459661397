// this file is automatically generated by git.settings.ts script
// eslint-disable
export const versions = {
  version: '2.2.3',
  revision: 'c81cb15a1edf8ced3774e199354b0e082e403544',
  branch: 'refs/tags/release/240722_v2.2.3'
 };
export const backendUrl = 'https://powerengine-api.power4people.nl/api';
export const assetsFolder = 'assetsc81cb15a1edf8ced3774e199354b0e082e403544';
export const versionCheck = 'https://powerengine.power4people.nl/version.json';
export const owoActive = false;
export const owoFrontendUrl = 'https://onlinewerkomgeving.power4people.nl';
